import React from "react";
import SanityImage from "../SanityImage";

const ImageUI = ({ input }) => {
  // console.log(input);

  return (
    <section className='module module-image text-center'>
      <SanityImage
        input={input.image}
        caption={input.caption}
        showPlaceholder={false}
      />
    </section>
  );
};

export default ImageUI;
