import React from "react";
import clsx from "clsx";
import SanityTexte from "../SanityTexte";
import SanityImage from "../SanityImage";
import AddToCart from "../shop/AddToCart";
import styled from "styled-components";

const Image = styled.div`
  figure,
  .gatsby-image-wrapper {
    height: 100%;
  }
  img {
    object-position: top;
  }
`;

const ProductsUI = ({ input }) => {
  // console.log(input);
  return (
    <section className='products-ui'>
      {input.products.map(
        (
          { slug, title, texte, productInfos, excerptInfos, price, images },
          i
        ) => (
          <article className='product mb-lg ' key={i}>
            <div className='row'>
              <div className='col-md-6 col-xs-12'>
                <div className='md:flex md:flex-col md:justify-between min-h-main md:sticky- md:top-xl-'>
                  <div className='header'>
                    <h2 className='mb-md '>{title}</h2>
                    <div className='mb-md '>
                      <SanityTexte input={texte} />
                    </div>
                    <div className='mb-md text-sm'>
                      <SanityTexte input={productInfos} />
                    </div>
                  </div>
                  <div className='footer hidden-sm'>
                    <div className='text-red   p-sm md:px-0 md:py-md pb-_lg '>
                      <AddToCart
                        id={slug.current}
                        price={price}
                        url='/shop'
                        description={excerptInfos[0].children[0].text}
                        image={images[0].asset.url}
                        name={title}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-xs-12'>
                <div className='row'>
                  {images.map((image, j) => (
                    <div
                      className={
                        images.length > 1 ? "col-xs-12 col-md-6" : "col-xs"
                      }
                      key={j}>
                      <Image className='-ar-a4- bg-gray- mb-[12px]'>
                        <SanityImage
                          input={image}
                          showPlaceholder={false}
                          objectFit='contain'
                        />
                      </Image>
                    </div>
                  ))}
                </div>
              </div>

              <div className='sticky bottom-0 left-0 z-10 md:static bg-white md:bg-transparent w-full'>
                <div className='sm-only '>
                  <div className='text-red   p-sm md:px-0 md:py-md pb-_lg '>
                    <AddToCart
                      id={slug.current}
                      price={price}
                      url='/shop'
                      description={excerptInfos[0].children[0].text}
                      image={images[0].asset.url}
                      name={title}
                    />
                  </div>
                </div>
              </div>
            </div>
          </article>
        )
      )}
    </section>
  );
};

export default ProductsUI;
