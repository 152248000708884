import React from "react";
// import loadable from "@loadable/component";
import TexteImageUI from "./TexteImageUI";
import TexteUI from "./TexteUI";
import TexteImagesUI from "./TexteImagesUI";
import ImageUI from "./ImageUI";
import AnimationUI from "./AnimationUI";
// import VideoUI from "./VideoUI";
import SliderUI from "./SliderUI";
import WorksUI from "./WorksUI";
import ProductsUI from "./ProductsUI";
import HeadlineUI from "./HeadlineUI";
import VideoUI from "./VideoUI";
// const TexteImageUI = loadable(() => import("./TexteImageUI"));
// const ImageUI = loadable(() => import("./ImageUI"));
// const SliderUI = loadable(() => import("./SliderUI"));
// const AnimationUI = loadable(() => import("./AnimationUI"));
// const VideoUI = loadable(() => import("./VideoUI"));

// const FallBack = () => <div className='loader-fallback px-sm'>Loading...</div>;

const Modules = ({ input }) => {
  const _renderModules = () => {
    const _modules = input.map((module, i) => {
      switch (module._type) {
        case "headlineUI":
          return <HeadlineUI key={module._key} input={module} />;
        case "texteUI":
          return <TexteUI key={module._key} input={module} />;
        case "animationUI":
          return <AnimationUI key={module._key} input={module} />;
        case "texteImageUI":
          return <TexteImageUI key={module._key} input={module} />;
        case "texteImagesUI":
          return <TexteImagesUI key={module._key} input={module} />;
        case "imageUI":
          return <ImageUI key={module._key} input={module} />;
        case "sliderUI":
          return <SliderUI key={module._key} input={module} />;
        case "worksUI":
          return <WorksUI key={i} input={module} />;
        case "productsUI":
          return <ProductsUI key={i} input={module} />;
        case "videoUI":
          return <VideoUI key={i} input={module} />;
        default:
          return null;
      }
    });
    return _modules;
  };

  return <div className='modules'>{_renderModules()}</div>;
};

export default Modules;
