import React, { useEffect, useState } from "react";
import GridMatter from "../shapes/GridMatter";
import SanityTexte from "../SanityTexte";

const AnimationUI = ({ input }) => {
  const svgs = input.images.map((el) => el.asset.url);

  const [ready, setReady] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 450);
  }, []);

  return (
    <section className='module module-animation w-full h-screen cursor-move '>
      {ready && <GridMatter input={svgs} />}
      {input.texte && (
        <div
          className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-sm '
          style={{ lineHeight: "1.3333333333333333em" }}>
          <SanityTexte input={input.texte} />
        </div>
      )}
    </section>
  );
};

export default AnimationUI;
