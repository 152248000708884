import React, { useState } from "react";
import { _linkResolver } from "../../core/utils";
import SanityImage from "../SanityImage";
import LinkFadeLeft from "../ui/TransitionLinks/LinkFadeLeft";
import MenuWorks from "../MenuWorks";
import clsx from "clsx";
import styled from "styled-components";
import useDeviceDetect from "../../hooks/useDeviceDetect";

const Container = styled.section`
  @media all and (max-width: 1080px) {
    // .index {
    //   display: none;
    // }
    .overview {
      display: none;
    }
    &.is-overview {
      .index {
        display: block;
      }
      .overview {
        display: none;
      }
    }
    &.is-index {
      .overview {
        display: none;
      }
      .index {
        display: block;
      }
    }
  }
`;

const WorksUI = ({ input }) => {
  // const { isMobile } = useDeviceDetect();
  // const initialDisplay = isMobile ? "is-overview" : "";
  const [display, setDisplay] = useState("is-overview");
  // console.log(isMobile, display);

  return (
    <Container className={clsx("works-ui", display)}>
      {/* <div className='sm-only mb-_lg md:mb-md toggle-view'>
        <div className='flex justify-between'>
          <button
            onClick={() => setDisplay("is-overview")}
            className={display === "is-overview" ? "is-active" : ""}>
            Overview
          </button>
          <button
            onClick={() => setDisplay("is-index")}
            className={display === "is-index" ? "is-active" : ""}>
            Index
          </button>
        </div>
      </div> */}
      <div className='row'>
        <div className='col-md-6 col-xs-12'>
          {/* <div className='title mb-md hidden-sm'>Index</div> */}
          <div className='index'>
            <MenuWorks />
          </div>
        </div>
        <div className='col-md-6 col-xs-12'>
          {/* <div className='title mb-md hidden-sm'>Overview</div> */}
          <div className='overview hidden-sm'>
            <div className='grid grid-cols-2 md:grid-cols-3 gap-_sm md:gap-sm'>
              {input.items.map((item, i) => (
                <article key={i}>
                  <LinkFadeLeft to={_linkResolver(item)}>
                    {item.seo.metaImage && (
                      <SanityImage
                        input={item.seo.metaImage}
                        showPlaceholder={false}
                        objectFit='cover'
                      />
                    )}
                    <h2 className='text-sm py-_xs md:py-xs'>{item.title}</h2>
                  </LinkFadeLeft>
                </article>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default WorksUI;
