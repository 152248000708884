import React from "react";
import SanityTexte from "../SanityTexte";
import SanityImage from "../SanityImage";
import clsx from "clsx";
// import ImageUI from "./ImageUI";

const TexteImageUI = ({ input }) => {
  const { texte, image } = input;
  // console.log(image);
  const { aspectRatio } = image.image.asset.metadata.dimensions;
  return (
    <section className='module-texte-image mb-lg'>
      <div className='md:flex md:justify-between'>
        <div className='col-texte md:w-1/3 mb-md md:mb-0'>
          <div className='md:sticky md:top-xl'>
            <SanityTexte input={texte} />
          </div>
        </div>
        <div className={clsx("col-image  md:w-3/6 flex justify-end")}>
          <div
            className={clsx("inner", aspectRatio < 1 ? "md:w-1/2" : "w-full")}>
            <SanityImage
              input={image.image}
              caption={image.caption}
              showPlaceholder={false}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TexteImageUI;
