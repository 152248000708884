import React, { useEffect, useRef } from "react";
import Matter from "matter-js";
// import pathseg from "./pathseg.min.js";
// import texture1 from "./shape-1.png";
// import shape1 from "./shape-1.svg";
// import shape2 from "./shape-2.svg";
// import shape3 from "./shape-3.svg";
// import shape4 from "./shape-4.svg";
// import SvgInline from "./SvgInline";

const GridMatter = ({ input }) => {
  const sceneRef = useRef();

  const Engine = Matter.Engine,
    Render = Matter.Render,
    World = Matter.World,
    Bodies = Matter.Bodies,
    Svg = Matter.Svg,
    Vertices = Matter.Vertices,
    Composites = Matter.Composites,
    Common = Matter.Common,
    Mouse = Matter.Mouse,
    MouseConstraint = Matter.MouseConstraint;
  let render;
  // provide concave decomposition support library
  // Common.setDecomp(require("poly-decomp"));

  let engine;

  const select = (root, selector) => {
    return Array.prototype.slice.call(root.querySelectorAll(selector));
  };

  const loadSvg = (url) => {
    const header = {
      method: "GET",
      headers: { "Content-type": "image/svg+xml" },
      // mode: "no-cors",
      // credentials: "omit",
    };
    return fetch(url, header)
      .then((res) => res.text())
      .then((text) => {
        // console.log("loadSvg url : ", url);
        // console.log("loadSvg text : ", text);
        return new window.DOMParser().parseFromString(text, "text/xml");
      })
      .catch(console.error.bind(console));
  };

  const _format = () => {
    setViewPortSize({
      with: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  };

  const _random = (range) => {
    const [min, max] = range;
    // console.log(min);
    return Math.random() * (max - min) + min;
  };

  const _cleanUp = () => {
    Render.stop(render);
    World.clear(engine.world);
    Engine.clear(engine);
    render.canvas.remove();
    render.canvas = null;
    render.context = null;
    render.textures = {};
  };

  const _renderMatterWorld = () => {
    engine = Engine.create({
      // positionIterations: 20
    });

    render = Render.create({
      element: sceneRef.current,
      engine: engine,
      options: {
        width: window.innerWidth,
        height: window.innerHeight,
        pixelRatio: 2,
        background: "transparent",
        wireframes: false,
        // showBounds: true,
        // showConvexHulls: true,
      },
    });
    // engine.gravity.y = 0.015;
    engine.gravity.y = 0.1;
    // const ballA = Bodies.circle(210, 100, 30, { restitution: 0.5 });
    // const ballB = Bodies.circle(110, 50, 30, { restitution: 0.5 });

    World.add(engine.world, [
      rectangle(0, 0, 10000, 1, true), // top
      rectangle(0, window.innerHeight, 10000, 1, true), // bottom
      rectangle(-80, window.innerHeight / 2, 160, window.innerHeight, true), // left
      rectangle(
        window.innerWidth + 80,
        window.innerHeight / 2,
        160,
        1200,
        true
      ), // right
    ]);

    // World.add(engine.world, [ballA, ballB]);

    if (!"ontouchstart" in window) {
      // add mouse control
      const mouse = Mouse.create(render.canvas),
        mouseConstraint = MouseConstraint.create(engine, {
          mouse: mouse,
          constraint: {
            stiffness: 0.2,
            render: {
              visible: true,
            },
          },
        });

      mouseConstraint.mouse.element.removeEventListener(
        "mousewheel",
        mouseConstraint.mouse.mousewheel
      );
      mouseConstraint.mouse.element.removeEventListener(
        "DOMMouseScroll",
        mouseConstraint.mouse.mousewheel
      );
    }

    // if (
    //   typeof window !== "undefined"
    //   // && typeof window.orientation !== "undefined"
    // ) {
    //   let touchStart;
    //   mouseConstraint.mouse.element.addEventListener("touchstart", (event) => {
    //     if (!mouseConstraint.body) {
    //       touchStart = event;
    //     }
    //   });

    //   mouseConstraint.mouse.element.addEventListener("touchend", (event) => {
    //     if (!mouseConstraint.body) {
    //       const startY = touchStart.changedTouches[0].clientY;
    //       const endY = event.changedTouches[0].clientY;
    //       const delta = Math.abs(startY - endY);

    //       if (delta > 80) {
    //         // window.scrollTo(0, 600);
    //         window.scrollBy(0, delta);
    //       }
    //     }
    //   });
    // }

    // if (typeof window !== "undefined") {
    //   var updateGravity = function (event) {
    //     var orientation =
    //         typeof window.orientation !== "undefined" ? window.orientation : 0,
    //       gravity = engine.gravity;
    //     console.log(window.orientation);
    //     if (orientation === 0) {
    //       gravity.x = Common.clamp(event.gamma, -90, 90) / 90;
    //       gravity.y = Common.clamp(event.beta, -90, 90) / 90;
    //     } else if (orientation === 180) {
    //       gravity.x = Common.clamp(event.gamma, -90, 90) / 90;
    //       gravity.y = Common.clamp(-event.beta, -90, 90) / 90;
    //     } else if (orientation === 90) {
    //       gravity.x = Common.clamp(event.beta, -90, 90) / 90;
    //       gravity.y = Common.clamp(-event.gamma, -90, 90) / 90;
    //     } else if (orientation === -90) {
    //       gravity.x = Common.clamp(-event.beta, -90, 90) / 90;
    //       gravity.y = Common.clamp(event.gamma, -90, 90) / 90;
    //     }
    //   };

    //   window.addEventListener("deviceorientation", updateGravity);

    // }

    if (!"ontouchstart" in window) World.add(engine.world, mouseConstraint);

    // Engine.run(engine);
    Matter.Runner.run(engine);

    Render.run(render);
  };

  const rectangle = (x, y, width, height, isStatic) => {
    return Bodies.rectangle(x, y, width, height, {
      isStatic: isStatic,
      // render: {
      //   visible: false,
      // },
      render: {
        fillStyle: "transparent",
        strokeStyle: "transparent",
        lineWidth: 0,
      },
    });
  };

  // const circle = (x, y, radius, isStatic) => {
  //   return Bodies.circle(x, y, radius, {
  //     isStatic: isStatic,
  //     // render: {
  //     //   visible: false,
  //     // },
  //   });
  // };

  const _renderSvg = (el) => {
    loadSvg(el).then((root) => {
      // console.log(root);
      const viewBox = root.querySelector("svg").getAttribute("viewBox");
      const viewBoxParts = viewBox.split(" ");

      //get path points as vertex
      const hasPaths = select(root, "path").length > 0;
      if (hasPaths)
        // console.log(hasPaths);
        _renderVertices(
          root,
          parseFloat(viewBoxParts[2]),
          parseFloat(viewBoxParts[3])
        );
      else
        _renderSprite(
          el,
          parseFloat(viewBoxParts[2]),
          parseFloat(viewBoxParts[3])
        );
    });
  };

  const _renderVertices = (root, width, height) => {
    const path = select(root, "path");

    const fillStyle = path[0].getAttribute("fill")
      ? path[0].getAttribute("fill")
      : "black";
    const strokeStyle = path[0].getAttribute("stroke")
      ? path[0].getAttribute("stroke")
      : "transparent";
    // console.log(fillStyle);
    // console.log(vertexSets);

    const vertexSets = path.map((path) => {
      // return Svg.pathToVertices(path, 10);
      return Vertices.scale(Svg.pathToVertices(path, 30), 0.8, 0.8);
    });

    if (vertexSets) {
      const x = _random([window.innerWidth / 4, (window.innerWidth / 4) * 3]);
      const y = _random([height, height * 2]);

      //[flagInternal=false], [removeCollinear=0.01], [minimumArea=10], [removeDuplicatePoints=0.01]
      const shape = Bodies.fromVertices(
        x,
        y,
        vertexSets,
        {
          isStatic: false,
          restitution: 1,
          friction: 0.75,
          // frictionAir: 0.01,
          density: 0.002,
          // slop: 1,
          render: {
            fillStyle: fillStyle,
            strokeStyle: fillStyle,
            lineWidth: 1,
          },
        },
        true
        // false,
        // 0.1,
        // 10,
        // 0.01
      );
      if (window.innerWidth <= 768) {
        Matter.Body.scale(shape, 0.5, 0.5);
      }
      // console.log(shape);
      World.add(engine.world, [shape]);
    }
  };

  const _renderSprite = (sprite, width, height) => {
    console.log(width, height, sprite);

    const x = _random([window.innerWidth / 4, (window.innerWidth / 4) * 3]);
    const y = _random([height, height * 2]);
    const shape = Bodies.rectangle(x, y, width, height, {
      isStatic: false,
      restitution: 1,
      friction: 0.01,
      frictionAir: 0.01,
      slop: 1,
      render: {
        strokeStyle: "#000000",
        sprite: {
          texture: sprite,
          // xScale: 2,
          // yScale: 2,
        },
      },
    });

    World.add(engine.world, [shape]);
  };

  const _renderSvgs = () => {
    input.forEach((el) => {
      _renderSvg(el);
    });
  };
  const _renderShapesTest = () => {
    _renderSvg(
      "https://cdn.sanity.io/images/k28us04g/production/c076452ae27d509a1f5083987d09ab14972946a5-512x512.svg"
    );
  };

  useEffect(() => {
    _renderMatterWorld();

    setTimeout(() => {
      // console.log(window.decomp);
      Common.setDecomp(window.decomp);
      _renderSvgs();
      // _renderShapesTest();
    }, 250);

    return () => _cleanUp();
  }, [_renderMatterWorld, _renderSvg]);

  // let granted = false;
  // const _grant = (e) => {
  //   if (
  //     typeof DeviceMotionEvent !== "undefined" &&
  //     typeof DeviceMotionEvent.requestPermission === "function"
  //   ) {
  //     DeviceOrientationEvent.requestPermission()
  //       .then((response) => {
  //         console.log(response);
  //         // alert(response);
  //         if (response == "granted") {
  //           e.target.style.display = "none";
  //           granted = true;
  //           window.addEventListener("deviceorientation", (e) => {
  //             console.log(e);
  //             var orientation =
  //                 typeof window.orientation !== "undefined"
  //                   ? window.orientation
  //                   : 0,
  //               gravity = engine.gravity;
  //             console.log(window.orientation);
  //             if (orientation === 0) {
  //               gravity.x = Common.clamp(event.gamma, -90, 90) / 90;
  //               gravity.y = Common.clamp(event.beta, -90, 90) / 90;
  //             } else if (orientation === 180) {
  //               gravity.x = Common.clamp(event.gamma, -90, 90) / 90;
  //               gravity.y = Common.clamp(-event.beta, -90, 90) / 90;
  //             } else if (orientation === 90) {
  //               gravity.x = Common.clamp(event.beta, -90, 90) / 90;
  //               gravity.y = Common.clamp(-event.gamma, -90, 90) / 90;
  //             } else if (orientation === -90) {
  //               gravity.x = Common.clamp(-event.beta, -90, 90) / 90;
  //               gravity.y = Common.clamp(event.gamma, -90, 90) / 90;
  //             }
  //           });
  //         }
  //       })
  //       .catch(console.error);
  //   } else {
  //     e.target.style.display = "none";
  //   }
  // };

  return (
    <div className='grid-matter-wrapper w-full h-screen overflow-hidden'>
      <div className='animation' ref={sceneRef}></div>
      {/* {!granted && (
        <button
          onClick={(e) => _grant(e)}
          className='sm-only absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2  bg-black- text-white- p-xs rounded'>
          Request orientation permission
        </button>
      )} */}
    </div>
  );
};

export default GridMatter;
