import React, { useEffect, useState } from "react";
import PubSub from "pubsub-js";

const AddToCart = ({ id, price, url, description, image, name }) => {
  const [status, setStatus] = useState("Add to Cart");

  useEffect(() => {
    const token1 = PubSub.subscribe("item.adding", (evt, item) => {
      if (item.id === id) {
        setStatus("Adding to cart");
      }
    });
    const token2 = PubSub.subscribe("item.added", (evt, item) => {
      if (item.id === id) {
        setStatus("Item added to cart");
      }
    });

    return () => {
      PubSub.unsubscribe(token1);
      PubSub.unsubscribe(token2);
    };
  }, []);

  return (
    <div className='add-to-cart'>
      <button
        className='snipcart-add-item  flex '
        data-item-id={id}
        data-item-price={price}
        data-item-url={url}
        data-item-description={description}
        data-item-image={image}
        data-item-name={name}>
        <span className='price pr-lg'>{price}€</span>
        <span>{status}</span>
      </button>
    </div>
  );
};

export default AddToCart;
