import React from "react";
// import PortableText from "@sanity/block-content-to-react";
import SanityTexte from "../SanityTexte";

const TexteUI = ({ input }) => {
  const { texte, title } = input;
  return (
    <section className='module-texte mb-lg'>
      <div className='flex flex-col md:flex-row justify-between'>
        <div className='texte md:w-1/3 mb-sm md:mb-0 '>
          <h2>{title}</h2>
          <SanityTexte input={texte} />
        </div>
      </div>
    </section>
  );
};

export default TexteUI;
