import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Modules from "../components/modules";

import clsx from "clsx";
import { useEffect } from "react";
// import clsx from "clsx";

export const query = graphql`
  query PageBySlug($slug: String!) {
    sanityPageModulaire(slug: { current: { eq: $slug } }) {
      id
      _id
      seo {
        metaTitle
        metaDescription
        metaImage {
          asset {
            url
          }
        }
      }
      home
      slug {
        current
      }
      title
      modules {
        ... on SanityHeadlineUI {
          _key
          _type
          title
        }
        ... on SanityTexteUI {
          _key
          _type
          title
          texte: _rawTexte(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityAnimationUI {
          _key
          _type
          images: _rawImages(resolveReferences: { maxDepth: 10 })
          texte: _rawTexte(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityTexteImageUI {
          _key
          _type
          texte: _rawTexte(resolveReferences: { maxDepth: 10 })
          image: _rawImage(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityTexteImagesUI {
          _key
          _type
          texte: _rawTexte(resolveReferences: { maxDepth: 10 })
          images: _rawImages(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityImageUI {
          _key
          _type
          image: _rawImage(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityVideoUI {
          _key
          _type
          video {
            asset {
              url
            }
          }
        }
        ... on SanitySliderUI {
          _key
          _type
          images: _rawImages(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityWorksUI {
          _type

          items {
            _type
            slug {
              current
            }
            title
            seo {
              metaImage: _rawMetaImage(resolveReferences: { maxDepth: 10 })
            }
          }
        }
        ... on SanityProductsUI {
          _type
          products {
            slug {
              current
            }
            title
            price
            texte: _rawTexte(resolveReferences: { maxDepth: 10 })
            productInfos: _rawProductInfos(resolveReferences: { maxDepth: 10 })
            excerptInfos: productInfos {
              children {
                text
              }
            }
            images: _rawImages(resolveReferences: { maxDepth: 10 })
          }
        }
      }
    }
  }
`;

const Modulaire = ({ data }) => {
  // console.log(data);
  const { seo, slug, home, modules } = data.sanityPageModulaire;
  // console.log(seo);
  useEffect(() => {
    if (home) {
      document.documentElement.classList.add("is-home");
    } else {
      document.documentElement.classList.remove("is-home");
    }
  }, [home]);

  return (
    <div className={clsx("main-area", !home ? "px-_md md:px-md " : "")}>
      {seo && (
        <Seo
          metaTitle={seo.metaTitle}
          metaDescription={seo.metaDescription}
          metaImage={seo.metaImage?.asset.url}
          template={`page-modulaire page-${slug.current}`}
          page={!home}
        />
      )}

      {modules && <Modules input={modules} />}
    </div>
  );
};

export default Modulaire;
