import React from "react";
import VideoPlayer from "../ui/VideoPlayer";
import styled from "styled-components";

const Section = styled.section`
  video {
    max-height: 100vh;
    object-fit: cover;
  }
`;

const VideoUI = ({ input }) => {
  // console.log(input);
  return (
    <Section className='module module-video h-full h-app'>
      {/* <video
        src={input.video.asset.url}
        className='w-screen h-screen object-cover'></video> */}
      <VideoPlayer input={input.video.asset.url} />
    </Section>
  );
};

export default VideoUI;
