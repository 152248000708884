import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import LinkFadeLeft from "./ui/TransitionLinks/LinkFadeLeft";
import { _linkResolver } from "../core/utils";
import clsx from "clsx";
// import HoverImage from "./ui/HoverImage";
import SanityImage from "./SanityImage";
import styled from "styled-components";

const query = graphql`
  query {
    allSanityMenu(filter: { location: { eq: "work" } }) {
      nodes {
        title
        nav {
          _type
          label
          link {
            ... on SanityWork {
              _type
              slug {
                current
              }
              seo {
                metaImage: _rawMetaImage(resolveReferences: { maxDepth: 10 })
              }
            }
          }
        }
      }
    }
  }
`;

const Li = styled.li`
  &:hover {
    .image-preview {
      opacity: 1;
    }
  }
`;

const MenuWorks = () => {
  const { allSanityMenu } = useStaticQuery(query);
  // const [image, setImage] = useState(null);
  // console.log(image);
  // console.log(allSanityMenu.nodes);
  return (
    <aside className='menu-works'>
      <div className='navs grid- grid-cols-2'>
        <div className='nav-groups'>
          {allSanityMenu.nodes.map((item, i) => (
            <div className='nav-group mb-md' key={i}>
              {item.title && <div className='title mb-sm'>{item.title}</div>}
              <nav>
                <ul>
                  {item.nav.map((li, j) => (
                    <Li
                      key={j}
                      className={clsx(
                        "pl-md md:pl-lg relative md:grid grid-cols-2",
                        `work-${li.link?.slug.current}`
                      )}>
                      {/* <pre>{JSON.stringify(li.link)}</pre> */}
                      <LinkFadeLeft to={_linkResolver(li.link)}>
                        {li.label}
                      </LinkFadeLeft>
                      <div className='image-preview opacity-0 hidden-sm'>
                        <div className='absolute top-0'>
                          {li.link && li.link.seo.metaImage && (
                            <SanityImage
                              input={li.link.seo.metaImage}
                              showPlaceholder={false}
                            />
                          )}
                        </div>
                      </div>
                    </Li>
                  ))}
                </ul>
              </nav>
            </div>
          ))}
        </div>
      </div>
    </aside>
  );
};

export default MenuWorks;
