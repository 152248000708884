import React, { useEffect, useRef } from "react";
// import PubSub from "pubsub-js";
import SlickSlider from "react-slick";
import styled from "styled-components";
import SanityImage from "../SanityImage";
import { pad2 } from "../../core/utils";

const Container = styled.div`
  button {
    &.slick-prev,
    &.slick-next {
      position: absolute;
      top: 0;
      width: 30%;
      height: 100%;
      text-indent: -9999px;
      background: transparent;
      z-index: 20;
      display: block !important;

      &.slick-prev {
        left: 0;
        cursor: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='31.61' height='21.21' viewBox='0 0 31.61 21.21'%3e%3ctitle%3eFichier 2%3c/title%3e%3cpolygon points='31.61 9.61 3.83 9.61 12.02 1.42 10.61 0 1.42 9.19 0 10.61 1.42 12.02 10.61 21.21 12.02 19.8 3.83 11.61 31.61 11.61 31.61 9.61'/%3e%3c/svg%3e")
            15 11,
          w-resize;
      }

      &.slick-next {
        right: 0;
        cursor: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31.61 21.21' width='31.61' height='21.21'%3e%3ctitle%3eFichier 1%3c/title%3e%3cpolygon points='0 11.61 27.78 11.61 19.59 19.8 21 21.21 30.19 12.02 31.61 10.61 30.19 9.19 21 0 19.59 1.42 27.78 9.61 0 9.61 0 11.61'/%3e%3c/svg%3e")
            15 11,
          e-resize;
      }
    }
  }
  .gatsby-image-wrapper [data-main-image] {
    max-height: 100vh;
    // margin: auto;
  }
  img {
    max-height: var(--main-height);
  }
  .pager {
    line-height: 1;
    @media all and (max-width: 768px) {
      color: black;
    }
  }
`;

export default ({ input }) => {
  // const { isMobile } = useContext(WrapperContext);
  // const [current, setCurrent] = useState(1);
  // const [length, setLength] = useState(input.length || 0);
  const isMobile = false;

  const sliderRef = useRef();
  const titleRef = useRef();
  const currentRef = useRef();
  // console.log(input);
  useEffect(() => {
    document.addEventListener("keydown", _onKey);

    return () => document.removeEventListener("keydown", _onKey);
  }, []);

  const _onKey = (e) => {
    // console.log(e.keyCode);
    switch (e.keyCode) {
      case 37:
        sliderRef.current.slickPrev();
        break;
      case 39:
        sliderRef.current.slickNext();
        break;
    }
  };
  // const _onEdge = () => {};
  // const _sliderBeforeChange = (oldIndex, newIndex) => {};
  const _sliderAfterChange = (index) => {
    // setCurrent(index);

    titleRef.current.innerText = input.images[index].caption;
    currentRef.current.innerText = pad2(index + 1);
  };

  const settings = {
    // fade: !isMobile,
    dots: false,
    speed: isMobile ? 400 : 300,
    // swipe: isMobile
    cssEase: isMobile ? "ease" : "cubic-bezier(0.53, 0, 0.36, 1)",

    // lazyLoad: true,
    // beforeChange: _sliderBeforeChange,
    afterChange: _sliderAfterChange,
    // onEdge: _onEdge,
  };
  // console.log(input.images);
  return (
    <Container className='module-slider h-main'>
      <SlickSlider ref={sliderRef} {...settings}>
        {input.images.map(({ image }, i) => (
          <div className='slide h-main w-screen' key={i}>
            <SanityImage
              input={image}
              objectFit='contain'
              showPlaceholder={false}
            />
          </div>
        ))}
      </SlickSlider>

      <div className='pager fixed bottom-0 w-full flex justify-between  text-white p-md'>
        <div className='length tabnum'>{pad2(input.images.length)}</div>
        <div className='title text-center uppercase' ref={titleRef}>
          {input.images[0].caption}
        </div>
        <div className='current tabnum' ref={currentRef}>
          {pad2(1)}
        </div>
      </div>
    </Container>
  );
};

// export default SliderUI;
