import React from "react";

const HeadlineUI = ({ input }) => {
  return (
    <section className='module-headline mb-md md:mb-md'>
      <h2>{input.title}</h2>
    </section>
  );
};

export default HeadlineUI;
